import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Col, Row, Container, Button } from "react-bootstrap";
import parse from "html-react-parser";

import { GatsbySeo } from "gatsby-plugin-next-seo";
import Navigation from "../components/navigation";
import SafeHtmlParser from "../components/safe-html-parser";
import { BgImage } from "gbimage-bridge";
import { Helmet } from "react-helmet";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import EventForm from "../components/event-form";

const PolicyTemplate = ({ data }) => {
	const { wpEvent } = data;
	const { siteUrl } = data.site.siteMetadata;
	const { seoFields } = wpEvent;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Events",
				item: {
					url: `${siteUrl}/events`,
					id: `${siteUrl}/events`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${wpEvent.title}`,
				item: {
					url: `${siteUrl}/events/${wpEvent.slug}`,
					id: `${siteUrl}/events/${wpEvent.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/events/${wpEvent.slug}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<section className="py-5 py-lg-7">
				<Container>
					<Row>
						<Col>
							<Row className="align-items-center">
								<Col xl={10} md={8} lg={9} className="">
									<h1 className=" display-5 ">{wpEvent.title}</h1>
								</Col>
								{wpEvent.eventsField.heroSection.bookNowLink?.url !== null &&
									wpEvent.eventsField.heroSection.bookNowLink?.url.length >
										0 && (
										<Col md={4} xl={2} lg={3}>
											<Button
												className=" p-2 px-4"
												href={wpEvent.eventsField.heroSection.bookNowLink.url}
												variant="primary "
											>
												{wpEvent.eventsField.heroSection.bookNowLink.title}
											</Button>
										</Col>
									)}
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<GatsbyImage
								className=" w-100 my-4"
								image={
									wpEvent.eventsField.heroSection.image.localFile
										.childImageSharp.gatsbyImageData
								}
								alt={wpEvent.eventsField.heroSection.image.altText}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="d-flex align-items-center">
								<div className="d-block me-4 me-lg-5">
									<p className="pb-0 mb-0">Event date</p>
									<p className="Garamond-Bold fs-5">
										{wpEvent.eventsField.heroSection.eventDate}
									</p>
								</div>

								<div className="d-block">
									<p className="pb-0 mb-0">Time</p>
									<p className="Garamond-Bold fs-5">
										{wpEvent.eventsField.heroSection.time}
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="align-items-center g-5">
						<Col lg={{ span: 6, order: "last" }}>
							<GatsbyImage
								className="w-100"
								image={
									wpEvent.eventsField.section2.image.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={wpEvent.eventsField.section2.image.altText}
							/>
						</Col>
						<Col lg={6}>
							<p className="Garamond-Bold">
								{wpEvent.eventsField.section2.tagline}
							</p>
							<h2 className="mb-3 display-5">
								{wpEvent.eventsField.section2.heading}
							</h2>
							<SafeHtmlParser htmlContent={wpEvent.eventsField.section2.body} />
							{wpEvent.eventsField.section2.buttons.map((item, index) => (
								<Button
									key={index}
									className="mt-3 me-3 px-4 py-2 w-100 w-md-auto"
									href={item.button.url}
									variant="outline-primary"
								>
									{item.button.title}
								</Button>
							))}
						</Col>
					</Row>
				</Container>
			</section>

			{wpEvent.eventsField.hasSection3 === true && (
				<section style={{ background: "#696669" }} className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center g-5">
							<Col lg={6}>
								<GatsbyImage
									className=" w-100 "
									image={
										wpEvent.eventsField.section3.image.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={wpEvent.eventsField.section3.image.altText}
								/>
							</Col>
							<Col className="text-white" lg={6}>
								<p className="Garamond-Bold text-white">
									{wpEvent.eventsField.section3.tagline}
								</p>
								<h2 className=" mb-3  display-5">
									{wpEvent.eventsField.section3.heading}
								</h2>
								<SafeHtmlParser
									htmlContent={wpEvent.eventsField.section3.body}
								/>
								<Button
									className="mt-3 px-4 py-2 w-100 w-md-auto"
									href={wpEvent.eventsField.section3.button.url}
									variant="outline-white "
								>
									{wpEvent.eventsField.section3.button.title}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			{wpEvent.eventsField.hasSection4 === true && (
				<section className="py-5 py-lg-7">
					<Container>
						<Row className="align-items-center g-5">
							<Col lg={{ span: 6, order: "last" }}>
								<GatsbyImage
									className=" w-100 "
									image={
										wpEvent.eventsField.section4.image.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={wpEvent.eventsField.section4.image.altText}
								/>
							</Col>
							<Col lg={6}>
								<p className="Garamond-Bold">
									{wpEvent.eventsField.section4.tagline}
								</p>
								<h2 className=" mb-3  display-5">
									{wpEvent.eventsField.section4.heading}
								</h2>
								<SafeHtmlParser
									htmlContent={wpEvent.eventsField.section4.body}
								/>
								<Button
									className="mt-3 px-4 py-2 w-100 w-md-auto"
									href={wpEvent.eventsField.section4.button.url}
									variant="outline-primary "
								>
									{wpEvent.eventsField.section4.button.title}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section id="form" className="py-5 py-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
							<h2 className="mb-4 text-center Garamond-Med display-5">
								{wpEvent?.eventsField?.formHeading || "Want to find out more?"}
							</h2>
							<EventForm event={wpEvent.title} />
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PolicyTemplate;

export const eventData = graphql`
	query ($id: String!) {
		wpEvent(id: { eq: $id }) {
			title
			slug
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			eventsField {
				cardDescription
				heroSection {
					eventDate
					time
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					bookNowLink {
						title
						url
					}
				}
				formHeading
				hasSection3
				hasSection4
				section2 {
					tagline
					heading
					body
					buttons {
						button {
							title
							url
						}
					}
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				section3 {
					tagline
					heading
					body
					button {
						title
						url
					}
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				section4 {
					tagline
					heading
					body
					button {
						title
						url
					}
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
