import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import { Col, Row, Container, Button } from "react-bootstrap";

function EventForm({ event }) {
	const [state, handleSubmit] = useForm("xkndpjyn");
	if (state.succeeded) {
		return (
			<div>
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row className="d-none">
						<Col md={6}>
							<Form.Group controlId="event">
								<Form.Control
									value={event}
									className="p-3"
									name="event"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="event"
								field="event"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row className="g-3 mb-3">
						<Col md={6}>
							<Form.Group controlId="name">
								<Form.Control
									className="p-3"
									name="name"
									placeholder="Name"
									type="text"
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col md={6}>
							<Form.Group controlId="phone">
								<Form.Control
									className="p-3"
									type="phone"
									name="phone"
									placeholder="Phone Number"
								/>
							</Form.Group>

							<ValidationError
								prefix="Phone"
								field="phone"
								errors={state.errors}
							/>
						</Col>
					</Row>
					<Row className="g-3 mb-3">
						<Col md={6}>
							<Form.Group controlId="email">
								<Form.Control
									className="p-3"
									name="email"
									type="email"
									placeholder="Email Address"
								/>
							</Form.Group>
							<ValidationError
								prefix="Email"
								field="email"
								errors={state.errors}
							/>
						</Col>
						<Col md={6}>
							<Form.Group controlId="numberOfPeople">
								<Form.Control
									className="p-3"
									name="numberOfPeople"
									type="number"
									placeholder="Number Of People"
								/>{" "}
							</Form.Group>
							<ValidationError
								prefix="numberOfPeople"
								field="numberOfPeople"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<Form.Group className="mb-3" controlId="instructions">
								<Form.Control
									name="How can we help you?"
									className="p-3"
									placeholder="How can we help you?"
									as="textarea"
									rows={3}
								/>
							</Form.Group>

							<ValidationError
								prefix="instructions"
								field="instructions"
								errors={state.errors}
							/>
						</Col>
					</Row>

					{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
					<Button
						className="btn  w-100 "
						type="submit"
						variant="outline-primary py-3"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Send Enquiry
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row className="d-none">
				<Col md={6}>
					<Form.Group controlId="event">
						<Form.Control
							value={event}
							className="p-3"
							name="event"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="event" field="event" errors={state.errors} />
				</Col>
			</Row>
			<Row className="g-3 mb-3">
				<Col md={6}>
					<Form.Group controlId="name">
						<Form.Control
							className="p-3"
							name="name"
							placeholder="Name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col md={6}>
					<Form.Group controlId="phone">
						<Form.Control
							className="p-3"
							type="phone"
							name="phone"
							placeholder="Phone Number"
						/>
					</Form.Group>

					<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				</Col>
			</Row>
			<Row className="g-3 mb-3">
				<Col md={6}>
					<Form.Group controlId="email">
						<Form.Control
							className="p-3"
							name="email"
							type="email"
							placeholder="Email Address"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
				<Col md={6}>
					<Form.Group controlId="numberOfPeople">
						<Form.Control
							className="p-3"
							name="numberOfPeople"
							type="number"
							placeholder="Number Of People"
						/>{" "}
					</Form.Group>
					<ValidationError
						prefix="numberOfPeople"
						field="numberOfPeople"
						errors={state.errors}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="instructions">
						<Form.Control
							name="How can we help you?"
							className="p-3"
							placeholder="How can we help you?"
							as="textarea"
							rows={3}
						/>
					</Form.Group>

					<ValidationError
						prefix="instructions"
						field="instructions"
						errors={state.errors}
					/>
				</Col>
			</Row>

			{/* <div
        class="g-recaptcha"
        data-sitekey=""
      ></div> */}
			<Button
				className="btn  w-100 "
				type="submit"
				variant="outline-primary py-3"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Send Enquiry
			</Button>
		</Form>
	);
}
export default EventForm;
